import { Game } from '@/types/game'
import { Player, PlayerInGame } from '@/types/player'

export const sortPlayerById = (a: PlayerInGame | Player, b: PlayerInGame | Player) =>
  a.id.localeCompare(b.id)

export const userMapper = {
  sortPlayersInGameById: (game: Game): Game => {
    return {
      ...game,
      players: game?.players.slice().sort(sortPlayerById),
    }
  },
}
