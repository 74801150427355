import axios, { AxiosInstance } from 'axios'
import { API_URLS } from '@/constants/staticUrls'

const baseApiAxiosInstance: AxiosInstance = axios.create({
  baseURL: API_URLS.baseApiUrl,
})

const raceApiAxiosInstance: AxiosInstance = axios.create({
  baseURL: API_URLS.raceApiUrl,
})

const tonCenterApiAxiosInstance: AxiosInstance = axios.create({
  baseURL: API_URLS.tonCenterApiUrl,
})

const setAuthBaseToken = async (token: string | null) => {
  if (token) {
    baseApiAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete baseApiAxiosInstance.defaults.headers.common['Authorization']
  }
}

const setAuthRaceToken = async (token: string | null) => {
  if (token) {
    raceApiAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete raceApiAxiosInstance.defaults.headers.common['Authorization']
  }
}

export {
  setAuthBaseToken,
  setAuthRaceToken,
  baseApiAxiosInstance,
  raceApiAxiosInstance,
  tonCenterApiAxiosInstance,
}
