import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'

export interface TimeState {
  timeDifference: number
  roundTripTime: number
}

const initialState: TimeState = {
  timeDifference: 0,
  roundTripTime: 0,
}

export const timeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    setTimeDifference: (state, action: PayloadAction<number>) => {
      state.timeDifference = action.payload
    },
    setRoundTripTime: (state, action: PayloadAction<number>) => {
      state.roundTripTime = action.payload
    },
  },
})

export const { setTimeDifference, setRoundTripTime } = timeSlice.actions

const selectTime = (state: RootState) => state.time

export const selectTimeDifference = (state: RootState) =>
  selectTime(state).timeDifference

export const selectRoundTripTime = (state: RootState) =>
  selectTime(state).roundTripTime

export default timeSlice.reducer
