import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'

export interface QueryOptionsState {
  user: {
    triggerReFetch: boolean
    reFetchInterval: false | number
  }
  activeGames: {
    triggerReFetch: boolean
    reFetchInterval: false | number
  }
  auth: {
    triggerReFetch: boolean
    reFetchInterval: false | number
  }
  time: {
    triggerReFetch: boolean
  }
}

const initialState: QueryOptionsState = {
  user: {
    triggerReFetch: false,
    reFetchInterval: false,
  },
  activeGames: {
    triggerReFetch: false,
    reFetchInterval: false,
  },
  auth: {
    triggerReFetch: false,
    reFetchInterval: false,
  },
  time: {
    triggerReFetch: false,
  },
}

export const queryOptionsSlice = createSlice({
  name: 'queryOptions',
  initialState,
  reducers: {
    setQueryOptionsUserTriggerReFetch: (state) => {
      state.user.triggerReFetch = !state.user.triggerReFetch
    },
    setQueryOptionsUserReFetchInterval: (state, action: PayloadAction<false | number>) => {
      state.user.reFetchInterval = action.payload
    },
    setQueryOptionsActiveGamesTriggerReFetch: (state) => {
      state.activeGames.triggerReFetch = !state.activeGames.triggerReFetch
    },
    setQueryOptionsActiveGamesReFetchInterval: (state, action: PayloadAction<false | number>) => {
      state.activeGames.reFetchInterval = action.payload
    },
    setQueryOptionsAuthTriggerReFetch: (state) => {
      state.auth.triggerReFetch = !state.auth.triggerReFetch
    },
    setQueryOptionsTimeTriggerReFetch: (state) => {
      state.time.triggerReFetch = !state.time.triggerReFetch
    },
  },
})

export const {
  setQueryOptionsUserTriggerReFetch,
  setQueryOptionsUserReFetchInterval,
  setQueryOptionsActiveGamesTriggerReFetch,
  setQueryOptionsActiveGamesReFetchInterval,
  setQueryOptionsAuthTriggerReFetch,
  setQueryOptionsTimeTriggerReFetch,
} = queryOptionsSlice.actions

const selectQueryOptions = (state: RootState) => state.queryOptions

export const selectQueryOptionsUserTriggerReFetch = (state: RootState) =>
  selectQueryOptions(state).user.triggerReFetch

export const selectQueryOptionsUserReFetchInterval = (state: RootState) =>
  selectQueryOptions(state).user.reFetchInterval

export const selectQueryOptionsActiveGamesTriggerReFetch = (state: RootState) =>
  selectQueryOptions(state).activeGames.triggerReFetch

export const selectQueryOptionsActiveGamesReFetchInterval = (state: RootState) =>
  selectQueryOptions(state).activeGames.reFetchInterval

export const selectQueryOptionsAuthTriggerReFetch = (state: RootState) =>
  selectQueryOptions(state).auth.triggerReFetch

export const selectQueryOptionsAuthReFetchInterval = (state: RootState) =>
  selectQueryOptions(state).auth.reFetchInterval

export const selectQueryOptionsTimeTriggerReFetch = (state: RootState) =>
  selectQueryOptions(state).time.triggerReFetch

export default queryOptionsSlice.reducer
