import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'
import { User, UserLevelData, UserResponse } from '@/types/user'

export interface UserState {
  user: User
  userLevelData: UserLevelData
}

const initialState: UserState = {
  user: {
    tgId: '',
    experience: 0,
    coupons: 0,
    energy: 0,
    lastUpdate: 0,
    multitapLevel: 0,
    energyPerMinLevel: 0,
    energyMaxLevel: 0,
    storageLimitLevel: 0,
    raceId: '',
    raceStatus: '',
    tokens: 0,
    refId: '',
    totalRaces: '0',
    refData: [],
    referralsLastDay: '0',
    racesLastDay: '0',
    inviteLimit: '0',
    raceData: [],
    waitingForTonGameData: {},
    nextFreeGameTimeMs: 0,
    userTonRaceData: {
      lastRaces: [],
      totalRaces: 0,
      totalRaces24: 0,
      totalWon: 0,
      totalWon24: 0,
    },
    tokensEarned: '0',
    tokensEarned24: '0',
    tonRefRewardToClaim: 0,
    tonRefRewardTotal: '0',
  },
  userLevelData: {
    multitapPriceAndBonus: { bonus: 0, price: 0, nextLevelBonus: 0 },
    energyPerMinPriceAndBonus: { bonus: 0, price: 0, nextLevelBonus: 0 },
    energyLimitMaxPriceAndBonus: { bonus: 0, price: 0, nextLevelBonus: 0 },
    storageLimitPriceAndBonus: { bonus: 0, price: 0, nextLevelBonus: 0 },
    storageLimit: 0,
    energyLimit: 0,
    replenishPerMin: 0,
    currentLevel: 0,
    pointsToUpgrade: 0,
  },
}

export const userSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setInitialUserData: (state, action: PayloadAction<UserResponse>) => {
      state.user = action.payload.user
      state.userLevelData = action.payload.userLevelData
    },
    setUserCoupons: (state, action: PayloadAction<number>) => {
      state.user.coupons =
        action.payload >= state.userLevelData.storageLimit
          ? state.userLevelData.storageLimit
          : action.payload
    },
    setUserEnergy: (state, action: PayloadAction<number>) => {
      state.user.energy = action.payload
    },
    setUserExperience: (state, action: PayloadAction<number>) => {
      state.user.experience = action.payload
    },
    setUserEnergyLimit: (state, action: PayloadAction<number>) => {
      state.userLevelData.energyLimit = action.payload
    },
    setUserRaceId: (state, action: PayloadAction<string>) => {
      state.user.raceId = action.payload
    },
  },
})

export const {
  setInitialUserData,
  setUserEnergy,
  setUserEnergyLimit,
  setUserCoupons,
  setUserExperience,
  setUserRaceId,
} = userSlice.actions

const selectUserData = (state: RootState) => state.userData

export const selectUser = (state: RootState) => selectUserData(state).user

export const selectUserLevelData = (state: RootState) => selectUserData(state).userLevelData

export default userSlice.reducer
