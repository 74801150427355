export const CACHE_KEYS = {
  auth: 'auth',
  player: 'player',
  wsAuth: 'wsAuth',
  serverTime: 'serverTime',
  games: 'games',
  userData: 'userData',
  updateUserData: 'updateUserData',
  userActiveGame: 'userActiveGame',
  game: 'game',
  userTasks: 'userTasks',
  stat: 'stat',
  tonExtendedAddressInformation: 'tonExtendedAddressInformation',
}
