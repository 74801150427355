import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'
import { PairType } from '@/types/player'
import { EntryPrice, TonEntryPrice } from '@/types/game'
import { RaceType } from '@/types/race'

export interface RacePreparationSliceState {
  entryPrice: EntryPrice | TonEntryPrice | null
  pair: PairType | null
  raceType: RaceType
}

const initialState: RacePreparationSliceState = {
  entryPrice: null,
  pair: null,
  raceType: RaceType.PlayWithPlatform,
}

export const racePreparationSlice = createSlice({
  name: 'racePreparation',
  initialState,
  reducers: {
    setRacePreparationEntryPrice: (
      state,
      action: PayloadAction<EntryPrice | TonEntryPrice | null>
    ) => {
      state.entryPrice = action.payload
    },
    setRacePreparationPair: (state, action: PayloadAction<PairType | null>) => {
      state.pair = action.payload
    },
    setRacePreparationRaceType: (state, action: PayloadAction<RaceType>) => {
      state.raceType = action.payload
    },
    clearRacePreparation: (state) => {
      state.entryPrice = null
      state.pair = null
      state.raceType = RaceType.PlayWithPlatform
    },
  },
})

export const {
  setRacePreparationRaceType,
  setRacePreparationPair,
  setRacePreparationEntryPrice,
  clearRacePreparation,
} = racePreparationSlice.actions

const selectRacePreparation = (state: RootState) => state.racePreparation

export const selectRacePreparationEntryPrice = (state: RootState) =>
  selectRacePreparation(state).entryPrice

export const selectRacePreparationPair = (state: RootState) => selectRacePreparation(state).pair

export const selectRacePreparationRaceType = (state: RootState) =>
  selectRacePreparation(state).raceType

export default racePreparationSlice.reducer
