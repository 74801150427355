'use client'

import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery_GetTonExtendedAddressInformation_TonCenter } from '@/services/useApi/tonCenter/useQuery'

export interface ITelegramContext {
  webApp?: WebApp
  user?: WebAppUser
}

export const TelegramContext = createContext<ITelegramContext>({})

export const TelegramProvider = ({ children }: { children: ReactNode }) => {
  const [webApp, setWebApp] = useState<WebApp | null>(null)

  useQuery_GetTonExtendedAddressInformation_TonCenter()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const checkTelegramWebApp = () => {
        const app = (window as any)?.Telegram?.WebApp

        if (app) {
          setWebApp(app)
          app.expand()
          app.ready()
          app.setHeaderColor('#090A0A')
        } else {
          setTimeout(checkTelegramWebApp, 100)
        }
      }

      checkTelegramWebApp()
    }
  }, [])

  const value = useMemo(() => {
    return webApp
      ? {
          webApp,
          user: webApp.initDataUnsafe.user,
        }
      : {}
  }, [webApp])

  return <TelegramContext.Provider value={value}>{children}</TelegramContext.Provider>
}

export const useTelegram = () => useContext(TelegramContext)
