import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'

export interface AuthState {
  isLoading: boolean
  isLoggedIn: boolean
  isLoggedInWs: boolean
}

const initialState: AuthState = {
  isLoading: true,
  isLoggedIn: false,
  isLoggedInWs: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    },
    setIsLoggedInWs: (state, action: PayloadAction<boolean>) => {
      state.isLoggedInWs = action.payload
    },
  },
})

export const { setIsLoading, setIsLoggedIn, setIsLoggedInWs } =
  authSlice.actions

const selectAuth = (state: RootState) => state.auth

export const selectIsLoadingAuth = (state: RootState) =>
  selectAuth(state).isLoading

export const selectIsLoggedIn = (state: RootState) =>
  selectAuth(state).isLoggedIn

export const selectIsLoggedInWs = (state: RootState) =>
  selectAuth(state).isLoggedInWs

export default authSlice.reducer
