import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'
import { ChartBarCandlestick, ChartBarResponse } from '@/types/chart'
import { PlayerInGame } from '@/types/player'
import { sortPlayerById } from '@/services/mappers/userMapper'
import { Time } from 'lightweight-charts'

export interface WsDataState {
  lastCandlePrice: number
  candlePriceList: Array<ChartBarCandlestick> | null
  isGameEnded: boolean
  calculationUpdates: Array<PlayerInGame>
  voteUpdates: Array<PlayerInGame>
}

type PayloadActionWithPlayerArray = PayloadAction<Array<PlayerInGame>>

const initialState: WsDataState = {
  candlePriceList: null,
  lastCandlePrice: 0,
  isGameEnded: false,
  calculationUpdates: [],
  voteUpdates: [],
}

export const wsDataStateSlice = createSlice({
  name: 'wsData',
  initialState,
  reducers: {
    setWsCandlePriceList: (
      state,
      action: PayloadAction<{
        candlePriceList: Array<ChartBarResponse> | null
        timeDifference: number
      }>
    ) => {
      if (!action.payload?.candlePriceList) {
        state.candlePriceList = null
        return
      }

      const now = Date.now() + action.payload.timeDifference

      if (
        Array.isArray(action.payload.candlePriceList) &&
        action.payload.candlePriceList.length > 1
      ) {
        const candlePriceListLength = action.payload.candlePriceList.length

        state.candlePriceList = action.payload.candlePriceList.map((item, index) => {
          const { open, low, high, close } = item
          const time = now - candlePriceListLength * 1000 + index * 1000
          const formattedTime = Math.floor(time / 1000)

          return {
            time: formattedTime as Time,
            open: Number(open),
            low: Number(low),
            high: Number(high),
            close: Number(close),
          }
        })
      }
    },
    setWsIsGameEnded: (state, action: PayloadAction<boolean>) => {
      state.isGameEnded = action.payload
    },
    setWsCalculationUpdates: (state, action: PayloadActionWithPlayerArray) => {
      state.calculationUpdates = action.payload?.slice().sort(sortPlayerById)
    },
    setWsVoteUpdates: (state, action: PayloadAction<Array<PlayerInGame>>) => {
      state.voteUpdates = action.payload
    },
    clearWsData: (state) => {
      state.isGameEnded = false
      state.calculationUpdates = []
      state.voteUpdates = []
      state.lastCandlePrice = 0
      state.candlePriceList = null
    },
    setWsLastCandlePrice: (state, action: PayloadAction<number>) => {
      state.lastCandlePrice = action.payload
    },
  },
})

export const {
  setWsCandlePriceList,
  setWsIsGameEnded,
  setWsCalculationUpdates,
  setWsVoteUpdates,
  clearWsData,
  setWsLastCandlePrice,
} = wsDataStateSlice.actions

const selectWsDataOptions = (state: RootState) => state.wsData

export const selectWsCandlePriceList = (state: RootState) =>
  selectWsDataOptions(state).candlePriceList

export const selectWsIsGameEnded = (state: RootState) => selectWsDataOptions(state).isGameEnded

export const selectWsCalculationUpdates = (state: RootState) =>
  selectWsDataOptions(state).calculationUpdates

export const selectWsVoteUpdates = (state: RootState) => selectWsDataOptions(state).voteUpdates

export const selectWsLastCandlePrice = (state: RootState) =>
  selectWsDataOptions(state).lastCandlePrice

export default wsDataStateSlice.reducer
