import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { CACHE_KEYS } from '@/constants/cacheKeys'
import { getTonExtendedAddressInformation } from '@/services/api/tonCenter'
import { TonFullAccountState } from '@/types/tonCenter'
import { useTonWallet } from '@tonconnect/ui-react'

export const useQuery_GetTonExtendedAddressInformation_TonCenter = (
  options?: UseQueryOptions<TonFullAccountState>
) => {
  const wallet = useTonWallet()

  return useQuery<TonFullAccountState>({
    queryKey: [CACHE_KEYS.tonExtendedAddressInformation, wallet?.account?.address],
    queryFn: async () => {
      return getTonExtendedAddressInformation(wallet?.account?.address as string)
    },
    ...options,
    enabled: !!wallet?.account?.address,
    select: (data) => ({
      ...data,
      balance: Number(data.balance) / 1e9,
    }),
  })
}
