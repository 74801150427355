import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'
import { IStatistic } from '@/types/general'

const initialState: IStatistic = {
  racesTotal: '0',
  usersTotal: '0',
  races24: '0',
  users24: '0',
  couponsTotal: '0',
  tokensTotal: '0',
  coupons24: '0',
  tokens24: '0',
  activeUsers: '0',
  tonRacesTotal: '0',
  tonRewardsTotal: '0',
  tonRaces24: '0',
  tonRewards24: '0',
}

export const statSlice = createSlice({
  name: 'stat',
  initialState,
  reducers: {
    setStatistic: (state, action: PayloadAction<IStatistic>) => {
      state.racesTotal = action.payload.racesTotal
      state.usersTotal = action.payload.usersTotal
      state.races24 = action.payload.races24
      state.users24 = action.payload.users24
      state.couponsTotal = action.payload.couponsTotal
      state.tokensTotal = action.payload.tokensTotal
      state.coupons24 = action.payload.coupons24
      state.tokens24 = action.payload.tokens24
      state.activeUsers = action.payload?.activeUsers || '0'
      state.tonRacesTotal = action.payload.tonRacesTotal
      state.tonRewardsTotal = action.payload.tonRewardsTotal
      state.tonRaces24 = action.payload.tonRaces24
      state.tonRewards24 = action.payload.tonRewards24
    },
  },
})

export const { setStatistic } = statSlice.actions

export const selectStat = (state: RootState) => state.stat

export default statSlice.reducer
