import { MaterialDesignContent } from 'notistack'
import styled from 'styled-components'

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#2D7738',
    fontSize: '12px',
    borderRadius: '16px',
    padding: '0 8px',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#D81A00',
    fontSize: '12px',
    borderRadius: '16px',
    padding: '0 8px',
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#6AAFFF',
    fontSize: '12px',
    borderRadius: '16px',
    padding: '0 8px',
  },
}))
