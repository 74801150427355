'use client'

import { FC, ReactNode, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useQuery } from '@tanstack/react-query'
import { CACHE_KEYS } from '@/constants/cacheKeys'
import { setRoundTripTime, setTimeDifference } from '@/redux/features/time'
import { fetchServerTime } from '@/services/api/time'
import { selectQueryOptionsTimeTriggerReFetch } from '@/redux/features/queryOptionsSlice'
import { selectIsLoggedIn } from '@/redux/features/authSlice'
import { updateTokens } from '@/services/api/auth'
import { useTelegram } from '@/app/providers/TelegramProvider'

interface Props {
  children: ReactNode
}

interface ITimeResponse {
  timeDifference: number
  roundTripTime: number
}

export const TimeProvider: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch()

  const timeTriggerReFetch = useAppSelector(selectQueryOptionsTimeTriggerReFetch)
  const isLogged = useAppSelector(selectIsLoggedIn)
  const { webApp } = useTelegram()

  const { data, error } = useQuery<ITimeResponse>({
    queryKey: [CACHE_KEYS.serverTime, timeTriggerReFetch],
    queryFn: async () => {
      const getServerTime = async () => {
        const localTimeStart = Date.now()
        const { RFC3339 } = await fetchServerTime()
        const localTimeEnd = Date.now()
        const serverTime = new Date(RFC3339).getTime()

        const averageLocalTime = (localTimeStart + localTimeEnd) / 2

        return {
          timeDifference: serverTime - averageLocalTime,
          roundTripTime: localTimeEnd - localTimeStart,
        }
      }

      try {
        return await getServerTime()
      } catch (e) {
        const telegramInitData = webApp?.initData as string
        await updateTokens(telegramInitData)

        return await getServerTime()
      }
    },
    enabled: isLogged,
  })

  useEffect(() => {
    if (typeof data !== 'undefined') {
      dispatch(setTimeDifference(data.timeDifference))
      dispatch(setRoundTripTime(data.roundTripTime))
    }
  }, [data])

  return children
}
