import { Tokens } from '@/constants/tokens'
import { PairType } from '@/types/player'
import { FunctionComponent, ReactElement, SVGProps } from 'react'

export interface JoinStepsFrom {
  name: string | null
  price: number | null
  pair: PairType | null
}

export interface AssetDataOld {
  from: Tokens
  fromImageUrl: string
  to: Tokens
  toImageUrl: string
  acceleration: number
  speed: number
  carImageUrl: string
  pair: PairType
}

export interface RacePreparationNitro {
  from: Tokens
  fromIcon: FunctionComponent<SVGProps<SVGSVGElement>>
  to: Tokens
  pair: PairType
}

export enum RacePreparationStep {
  ChooseNitro = 'ChooseNitro',
  ConfirmEntry = 'ConfirmEntry',
  LookingOpponent = 'LookingOpponent',
}

export enum RaceType {
  OneVsOne = '1 vs 1',
  PlayWithPlatform = 'Play with platform',
  OneVsThree = '1 vs 3',
}

export type TonRaceType = RaceType.OneVsOne | RaceType.OneVsThree
