import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/authSlice'
import wsOptionsReducer from './features/wsOptionsSlice'
import racePreparationReducer from './features/racePreparationSlice'
import queryOptionsReducer from './features/queryOptionsSlice'
import wsDataReducer from './features/wsDataSlice'
import raceReducer from './features/race'
import timeReducer from './features/time'
import routerReducer from './features/router'
import userDataReducer from './features/user'
import statReducer from './features/stat'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    wsOptions: wsOptionsReducer,
    wsData: wsDataReducer,
    racePreparation: racePreparationReducer,
    queryOptions: queryOptionsReducer,
    race: raceReducer,
    time: timeReducer,
    router: routerReducer,
    userData: userDataReducer,
    stat: statReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
