import { baseApiAxiosInstance, setAuthBaseToken, setAuthRaceToken } from './api'
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys'

export const authenticateUser = async (data: string, refId?: string | null): Promise<string> => {
  const response = await baseApiAxiosInstance.post('/auth', {
    telegramData: data,
    ...(refId && { refId }),
  })

  return response.data?.token
}

export const isTokenValid = async (): Promise<boolean> => {
  const response = await baseApiAxiosInstance.get('/verify')

  return response.data
}

export const updateTokens = async (telegramInitData: string, refId?: string | null) => {
  const newToken = await authenticateUser(telegramInitData, refId)

  window.localStorage.setItem(LOCAL_STORAGE_KEYS.token, newToken)

  await setAuthBaseToken(newToken)
  await setAuthRaceToken(newToken)

  return newToken
}
