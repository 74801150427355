export enum RoutePaths {
  main = '/',
  race = '/race',
  raceStepSelect = '/race-step-select',
  raceStepValidate = '/race-select-validate',
  raceStepChooseNitro = '/race-select-choose-nitro',
  racePodium = '/race-podium',
  tonRaceStepRaceType = '/ton-race-step-race-type',
  tonRaceStepChooseEntry = '/ton-race-step-entry',
  tonRaceStepChooseNitro = '/ton-race-step-choose-nitro',
  tonRaceStepWaiting = '/ton-race-step-waiting',
  profile = '/profile',
  boosters = '/boosters',
  rewards = '/rewards',
  info = '/info',
  gameRules = '/game-rules',
}
