import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'

export interface RaceState {
  isAllRaceActivitiesCompleted: boolean
}

const initialState: RaceState = {
  isAllRaceActivitiesCompleted: false,
}

export const raceSlice = createSlice({
  name: 'race',
  initialState,
  reducers: {
    setIsAllRaceActivitiesCompleted: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAllRaceActivitiesCompleted = action.payload
    },
    clearRace: (state) => {
      state.isAllRaceActivitiesCompleted = false
    },
  },
})

export const { setIsAllRaceActivitiesCompleted, clearRace } = raceSlice.actions

const selectRace = (state: RootState) => state.race

export const selectIsAllRaceActivitiesCompleted = (state: RootState) =>
  selectRace(state).isAllRaceActivitiesCompleted

export default raceSlice.reducer
