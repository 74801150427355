'use client'

import { FC, ReactNode, useEffect } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { selectIsLoggedIn, selectIsLoggedInWs, setIsLoggedInWs } from '@/redux/features/authSlice'
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys'
import {
  selectWsOptionsMessage,
  setWsOptionsMessage,
  setWsOptionsReadyState,
} from '@/redux/features/wsOptionsSlice'
import {
  setWsLastCandlePrice,
  setWsCalculationUpdates,
  setWsIsGameEnded,
  setWsCandlePriceList,
} from '@/redux/features/wsDataSlice'
import { WS_CHANNELS } from '@/constants/ws'
import { useQuery } from '@tanstack/react-query'
import { CACHE_KEYS } from '@/constants/cacheKeys'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { selectTimeDifference } from '@/redux/features/time'
import { selectRoute } from '@/redux/features/router'

interface Props {
  children: ReactNode
}

export const WsProvider: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch()

  const route = useAppSelector(selectRoute)

  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const isLoggedInWs = useAppSelector(selectIsLoggedInWs)

  const wsMessage = useAppSelector(selectWsOptionsMessage)
  const timeDifference = useAppSelector(selectTimeDifference)

  const { readyState, sendMessage, lastMessage } = useWebSocket(
    process.env.NEXT_PUBLIC_WS_API_URL || '',
    {},
    isLoggedIn && route === RoutePaths.race
  )

  useQuery({
    queryKey: [CACHE_KEYS.wsAuth, isLoggedIn, isLoggedInWs, readyState],
    queryFn: async () => {
      const lsToken = window.localStorage.getItem(LOCAL_STORAGE_KEYS.token)

      if (!!lsToken) {
        sendMessage(
          JSON.stringify({
            event: 'authenticate',
            token: `Bearer ${lsToken}`,
          })
        )
      }

      return null
    },
    refetchInterval: 3000,
    enabled: isLoggedIn && readyState === ReadyState.OPEN && !isLoggedInWs,
  })

  useEffect(() => {
    if (route !== RoutePaths.race) {
      dispatch(setIsLoggedInWs(false))
    }
  }, [route])

  useEffect(() => {
    dispatch(setWsOptionsReadyState(readyState))
  }, [readyState])

  useEffect(() => {
    if (!!wsMessage) {
      sendMessage(wsMessage)
      dispatch(setWsOptionsMessage(''))
    }
  }, [wsMessage])

  useEffect(() => {
    if (lastMessage?.data) {
      const data = JSON.parse(lastMessage.data)

      if (data.channel === WS_CHANNELS.calculationUpdates) {
        dispatch(setWsCalculationUpdates(data?.payload))
      }

      if (data.channel === WS_CHANNELS.gameEnd) {
        dispatch(setWsIsGameEnded(true))
      }

      if (data.channel === WS_CHANNELS.authentication && data?.payload?.success === true) {
        dispatch(setIsLoggedInWs(true))
      }

      if (data.channel.startsWith(WS_CHANNELS.priceUpdate)) {
        const price = parseFloat(data?.payload?.price)
        dispatch(setWsLastCandlePrice(price))
      }

      if (data.channel.startsWith(WS_CHANNELS.candleUpdate)) {
        dispatch(
          setWsCandlePriceList({
            candlePriceList: data?.payload,
            timeDifference: timeDifference,
          })
        )
      }
    }
  }, [lastMessage])

  return children
}
