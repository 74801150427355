'use client'

import { ReactNode } from 'react'
import { useQuery } from '@tanstack/react-query'
import { CACHE_KEYS } from '@/constants/cacheKeys'
import { setAuthBaseToken, setAuthRaceToken } from '@/services/api/api'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { setIsLoggedIn, setIsLoading, selectIsLoggedIn } from '@/redux/features/authSlice'
import {
  selectQueryOptionsAuthReFetchInterval,
  selectQueryOptionsAuthTriggerReFetch,
} from '@/redux/features/queryOptionsSlice'
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys'
import { isTokenValid, updateTokens } from '@/services/api/auth'
import { useQuery_FetchUserData_User } from '@/services/useApi/user/useQuery'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { parseRefId } from '@/services/mappers/parseRefId'
import { useSnackbar } from 'notistack'
import { fetchUserData } from '@/services/api/user'

export function AuthProvider({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  useQuery_FetchUserData_User()

  const queryOptionsAuthTriggerReFetch = useAppSelector(selectQueryOptionsAuthTriggerReFetch)

  const queryOptionsAuthReFetchInterval = useAppSelector(selectQueryOptionsAuthReFetchInterval)

  const { webApp, user } = useTelegram()

  useQuery({
    queryKey: [CACHE_KEYS.auth, queryOptionsAuthTriggerReFetch, isLoggedIn],
    queryFn: async () => {
      try {
        const telegramInitData = webApp?.initData as string
        const tgId = user?.id || ''

        const refId = parseRefId(window.location.search)

        let lsToken = window.localStorage.getItem(LOCAL_STORAGE_KEYS.token)
        let lsTgId = window.localStorage.getItem(LOCAL_STORAGE_KEYS.tgId)

        if (lsToken && lsTgId === tgId.toString()) {
          await setAuthBaseToken(lsToken)
          await setAuthRaceToken(lsToken)

          try {
            await isTokenValid()
          } catch (e) {
            window.localStorage.setItem(LOCAL_STORAGE_KEYS.tgId, tgId.toString())
            window.localStorage.removeItem(LOCAL_STORAGE_KEYS.token)

            await updateTokens(telegramInitData)
          }
        } else {
          window.localStorage.setItem(LOCAL_STORAGE_KEYS.tgId, tgId.toString())

          await updateTokens(telegramInitData, refId)
        }

        dispatch(setIsLoggedIn(true))
        dispatch(setIsLoading(false))

        return null
      } catch (e: any) {
        enqueueSnackbar('Error in Authorization: ' + e?.message, { variant: 'error' })
        throw new Error('Error in AuthProvider.tsx: ' + e?.message)
      }
    },
    enabled: !isLoggedIn && !!webApp?.initData,
    retry: 10,
  })

  return children
}
