'use client'

import { NextUIProvider } from '@nextui-org/react'
import { ReactNode } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from '@/redux/store'
import { AuthProvider } from './AuthProvider'
import { TimeProvider } from './TimeProvider'
import { WsProvider } from './WsProvider'
import { TelegramProvider } from './TelegramProvider'
import { TelegramButtonsProvider } from '@/app/providers/TelegramButtonsProvider'
import { GameProvider } from '@/app/providers/GameProvider'
import { SnackbarProvider } from 'notistack'
import { StyledMaterialDesignContent } from '@/constants/styledMaterialDesignContent'
import { TonConnectUIProvider } from '@tonconnect/ui-react'

export const queryClient = new QueryClient()

export default function Providers({ children }: { children: ReactNode }) {
  return (
    <NextUIProvider>
      <main className="custom-light max-w-screen-sm container">
        <TonConnectUIProvider manifestUrl="https://click-bot.nitro.taxi/tonconnect-manifest.json">
          <ReduxProvider store={store}>
            <QueryClientProvider client={queryClient}>
              <SnackbarProvider
                maxSnack={1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                Components={{
                  success: StyledMaterialDesignContent,
                  error: StyledMaterialDesignContent,
                  info: StyledMaterialDesignContent,
                }}
              >
                <TelegramProvider>
                  <TelegramButtonsProvider>
                    <AuthProvider>
                      <TimeProvider>
                        <WsProvider>
                          <GameProvider>{children}</GameProvider>
                        </WsProvider>
                      </TimeProvider>
                    </AuthProvider>
                  </TelegramButtonsProvider>
                </TelegramProvider>
              </SnackbarProvider>
            </QueryClientProvider>
          </ReduxProvider>
        </TonConnectUIProvider>
      </main>
    </NextUIProvider>
  )
}
