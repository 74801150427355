import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'
import { RoutePaths } from '@/services/routePaths/routePaths'

export interface RouteState {
  route: RoutePaths
}

const initialState: RouteState = {
  route: RoutePaths.main,
}

export const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setRoute: (state, action: PayloadAction<RoutePaths>) => {
      state.route = action.payload
    },
  },
})

export const { setRoute } = routerSlice.actions

const selectRouter = (state: RootState) => state.router

export const selectRoute = (state: RootState) => selectRouter(state).route

export default routerSlice.reducer
