import { baseApiAxiosInstance } from './api'
import {
  UserBoostType,
  UserPostData,
  UserResponse,
  UserTasksResponse,
  UserTaskType,
} from '@/types/user'

export const fetchUserData = async (id: string | number): Promise<UserResponse> => {
  const response = await baseApiAxiosInstance.get(`/user/${id}`)
  return response.data
}

export const updateUserData = async (
  id: string | number,
  data: UserPostData
): Promise<UserResponse> => {
  const response = await baseApiAxiosInstance.post(`/user/${id}`, data)
  return response.data
}

export const fetchUserTasks = async (): Promise<UserTasksResponse> => {
  const response = await baseApiAxiosInstance.get(`/user/tasks/list`)
  return response.data
}

export const claimUserTask = async (task: UserTaskType): Promise<UserTasksResponse> => {
  const response = await baseApiAxiosInstance.post(`/user/tasks/${task}`)
  return response.data
}

export const updateUserBoost = async (
  id: string | number,
  boostType: UserBoostType
): Promise<UserResponse> => {
  const response = await baseApiAxiosInstance.post(`/user/${id}/boosters`, { boosterId: boostType })
  return response.data
}

export const callRefLinkMessage = async (): Promise<{}> => {
  const response = await baseApiAxiosInstance.post(`/refLink`)
  return response.data
}

export const checkTonTransactions = async (): Promise<undefined> => {
  const response = await baseApiAxiosInstance.post('/race/checkTonTransactions')
  return response.data
}

export const cancelTonTransaction = async (tgId: string): Promise<undefined> => {
  const response = await baseApiAxiosInstance.post(`/user/${tgId}/cancelTonTransaction`)
  return response.data
}

export const claimTonRefRewards = async (tgId: string, address: string): Promise<undefined> => {
  const response = await baseApiAxiosInstance.post(`/user/${tgId}/claimTonRefRewards`, {
    address,
  })
  return response.data
}
