import { PairType } from '@/types/player'

export interface User {
  tgId: string
  experience: number
  coupons: number
  energy: number
  lastUpdate: number
  multitapLevel: number
  energyPerMinLevel: number
  energyMaxLevel: number
  storageLimitLevel: number
  raceId: string
  raceStatus: UserRaceStatus | string
  racesLastDay: string
  tokens: number
  refId: string
  totalRaces: string
  refData: Array<UserRefData>
  referralsLastDay: string
  inviteLimit: string
  raceData: Array<UserRaceData>
  waitingForTonGameData: WaitingForTonGameData
  nextFreeGameTimeMs: number
  userTonRaceData: UserTonRaceData
  tokensEarned: string
  tokensEarned24: string
  tonRefRewardToClaim: number
  tonRefRewardTotal: string
}

export interface UserTonLastRaceData {
  createdAt: string
  price: string
  userWon?: number
  userPlace?: number
  usersLength: number
}

export interface UserTonRaceData {
  totalRaces: number
  totalRaces24: number
  totalWon: number
  totalWon24: number
  lastRaces: UserTonLastRaceData[]
}

export interface WaitingForTonGameData {
  limit?: 2 | 4
  players?: number
  entryPrice?: string
  playersData?: Array<{
    pair: PairType
    tgId: string
    username: string
    receiverAddress: string
  }>
}

export interface UserRaceData {
  createdAd: string
  id: number
  place: number
  price: string
  reward: string
}

export interface UserRefData extends UserInvitedData {
  refData: Array<UserInvitedData>
}

export interface UserInvitedData {
  nickname: string
  isPremium: boolean
  level: number
  rewards: number
  tokens: number
}

export interface UserBoostData {
  bonus: number
  price: number
  nextLevelBonus: number
  isLastLevel?: boolean
}

export interface UserLevelData {
  multitapPriceAndBonus: UserBoostData
  energyPerMinPriceAndBonus: UserBoostData
  energyLimitMaxPriceAndBonus: UserBoostData
  storageLimitPriceAndBonus: UserBoostData
  storageLimit: number
  energyLimit: number
  replenishPerMin: number
  currentLevel: number
  pointsToUpgrade: number
}

export interface UserResponse {
  user: User
  userLevelData: UserLevelData
}

export interface UserPostData {
  experience: number
  coupons: number
  energy: number
}

export enum UserRaceStatus {
  ACTIVE = 'active',
  DONE = 'done',
  TON_PENDING = 'ton_pending',
  TON_IN_GAME = 'ton_in_game',
  TON_DONE = 'ton_done',
  TON_WAITING_FOR_TRANSACTION = 'ton_waiting_for_transaction',
  TON_WAITING_FOR_TRANSACTION_ERROR = 'waiting_for_transaction_error',
}

export interface UserDailyTask {
  day: number
  amount: number
  claimed: boolean
  claimDate: number
}

export interface UserTasksResponse {
  dailyRewards: Array<UserDailyTask>
  inviteFriend: UserTaskStatus
  invitePremiumFriend: UserTaskStatus
  joinTgChannel: UserTaskStatus
  joinTgChat: UserTaskStatus
}

export enum UserTaskStatus {
  ACTIVE = '0',
  READY_TO_CLAIM = '2',
  DONE = '3',
}

export enum UserTaskType {
  DAILY_REWARDS = 'daily',
  INVITE_FRIEND = 'friend',
  INVITE_PREMIUM_FRIEND = 'premFriend',
  JOIN_TG_CHANNEL = 'tgChannel',
  JOIN_TG_CHAT = 'tgChat',
}

export enum UserBoostType {
  MULTI_TAP_LEVEL = 1,
  ENERGY_PER_MIN_LEVEL = 2,
  ENERGY_LIMIT_LEVEL = 3,
  STORAGE_LIMIT_LEVEL = 4,
}
