'use client'

import { FC, ReactNode, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { selectRoute, setRoute } from '@/redux/features/router'
import { selectUser } from '@/redux/features/user'
import { UserRaceStatus } from '@/types/user'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { selectIsAllRaceActivitiesCompleted } from '@/redux/features/race'
import { useQuery_GetStatistics_Stat } from '@/services/useApi/stat/useQuery'
import { useTonWallet } from '@tonconnect/ui-react'
import useSound from 'use-sound'

interface Props {
  children: ReactNode
}

export const GameProvider: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch()

  const route = useAppSelector(selectRoute)
  const { raceStatus } = useAppSelector(selectUser)
  const isAllRaceActivitiesCompleted = useAppSelector(selectIsAllRaceActivitiesCompleted)
  const wallet = useTonWallet()

  const [play] = useSound('/audio/pop-ding.mp3')

  useQuery_GetStatistics_Stat()

  useEffect(() => {
    if (
      raceStatus === UserRaceStatus.ACTIVE &&
      route !== RoutePaths.race &&
      !isAllRaceActivitiesCompleted
    ) {
      dispatch(setRoute(RoutePaths.race))
    }

    if (
      raceStatus === UserRaceStatus.TON_IN_GAME &&
      route !== RoutePaths.race &&
      !isAllRaceActivitiesCompleted &&
      !!wallet?.account
    ) {
      play()
      dispatch(setRoute(RoutePaths.race))
    }
  }, [route, raceStatus, isAllRaceActivitiesCompleted, wallet?.account])

  useEffect(() => {
    if (
      raceStatus === UserRaceStatus.TON_PENDING &&
      route !== RoutePaths.tonRaceStepWaiting &&
      !!wallet?.account
    ) {
      dispatch(setRoute(RoutePaths.tonRaceStepWaiting))
    }
  }, [route, raceStatus, isAllRaceActivitiesCompleted, wallet?.account])

  return children
}
