export const parseRefId = (queryString: string | undefined): string | null => {
  const params = new URLSearchParams(queryString)
  let refId: string | null = null

  params.forEach((value) => {
    const match = value.match(/refId_(\d+)/)
    if (match) {
      refId = match[1]
    }
  })

  return refId
}
