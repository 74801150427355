import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { selectIsLoggedIn } from '@/redux/features/authSlice'
import { CACHE_KEYS } from '@/constants/cacheKeys'
import { IStatistic } from '@/types/general'
import { getStatistics } from '@/services/api/stat'
import { setStatistic } from '@/redux/features/stat'

export const useQuery_GetStatistics_Stat = (options?: UseQueryOptions<IStatistic>) => {
  const dispatch = useAppDispatch()

  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  return useQuery<IStatistic>({
    queryKey: [CACHE_KEYS.stat, isLoggedIn],
    queryFn: async () => {
      const data = await getStatistics()
      dispatch(setStatistic(data))

      return data
    },
    enabled: isLoggedIn,
    ...options,
  })
}
