import { AxiosError } from 'axios'

export const getErrorCancelMsg = (e: Error | undefined): string | null => {
  let error

  if (!e) {
    return null
  }

  if (e.message.includes('ser rejected the request')) {
    error = 'Transaction canceled by user'
  } else {
    error = null
  }

  return error
}

export const getErrorCancelMsgToString = (e: Error | undefined): string => {
  let error

  if (!e) {
    return 'Transaction failed, try again'
  }

  if (e?.message?.includes('ser rejected the request')) {
    error = 'Transaction canceled by user'
  } else {
    error = 'Transaction failed, try again'
  }

  return error
}

interface ErrorResponseData {
  desc?: string
}

export const getErrorRegistration = (
  error: AxiosError<ErrorResponseData> | undefined | null | Error
): string => {
  if (error?.message?.includes('ser rejected the request')) {
    return 'Transaction canceled by user'
  }

  let errorMsg = 'Something went wrong, please try again'

  if (error && 'response' in error && error.response) {
    const responseData = error.response?.data
    if (responseData?.desc && responseData.desc.includes("can't get user by")) {
      errorMsg = 'Invalid promo code, please check'
    }
  }

  return errorMsg
}

export const isJwtExpiredError = (
  error: AxiosError<ErrorResponseData> | undefined | null | Error
): boolean => {
  if (error && 'response' in error && error.response) {
    const responseData = error.response?.data as string
    return !!responseData && responseData?.includes('jwt')
  }

  return false
}
