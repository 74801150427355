import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { CACHE_KEYS } from '@/constants/cacheKeys'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { selectIsLoggedIn } from '@/redux/features/authSlice'
import { fetchUserTasks, fetchUserData, updateUserData } from '@/services/api/user'
import { selectRoute } from '@/redux/features/router'
import { UserResponse, UserTasksResponse } from '@/types/user'
import { selectUser, selectUserLevelData, setInitialUserData } from '@/redux/features/user'
import { AxiosError } from 'axios'
import { isJwtExpiredError } from '@/services/mappers/errors'
import { updateTokens } from '@/services/api/auth'
import {
  selectQueryOptionsUserReFetchInterval,
  selectQueryOptionsUserTriggerReFetch,
  setQueryOptionsUserTriggerReFetch,
} from '@/redux/features/queryOptionsSlice'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { queryClient } from '@/app/providers'
import { useEffect } from 'react'

export const useQuery_FetchUserData_User = (options?: UseQueryOptions<UserResponse>) => {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const route = useAppSelector(selectRoute)
  const userTriggerReFetch = useAppSelector(selectQueryOptionsUserTriggerReFetch)
  const userReFetchInterval = useAppSelector(selectQueryOptionsUserReFetchInterval)

  const { webApp, user } = useTelegram()

  return useQuery<UserResponse>({
    queryKey: [CACHE_KEYS.userData, route, user?.id, userTriggerReFetch],
    queryFn: async () => {
      try {
        const data = await fetchUserData(user?.id || '')

        dispatch(setInitialUserData(data))

        return data
      } catch (e: unknown) {
        if (e instanceof AxiosError && isJwtExpiredError(e)) {
          const telegramInitData = webApp?.initData as string
          await updateTokens(telegramInitData)

          return await fetchUserData(user?.id || '')
        } else {
          throw new Error('Error fetching user data')
        }
      }
    },
    enabled: isLoggedIn && !!user?.id && !!webApp?.initData,
    retry: 10,
    refetchInterval: userReFetchInterval,
    ...options,
  })
}

export const useQuery_UpdateUserData_User = (options?: UseQueryOptions<UserResponse>) => {
  const dispatch = useAppDispatch()

  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  const { experience, tgId, coupons, energy } = useAppSelector(selectUser)
  const { pointsToUpgrade } = useAppSelector(selectUserLevelData)

  const { webApp, user } = useTelegram()

  useEffect(() => {
    if (experience >= pointsToUpgrade) {
      dispatch(setQueryOptionsUserTriggerReFetch())
    }
  }, [experience, pointsToUpgrade])

  return useQuery<UserResponse>({
    queryKey: [CACHE_KEYS.updateUserData, isLoggedIn, experience, tgId, coupons],
    queryFn: async () => {
      try {
        if (experience >= pointsToUpgrade) {
          await queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.userData] })
        }

        if (!tgId) {
          throw new Error('User not found')
        }

        return await updateUserData(tgId, {
          experience: experience,
          coupons: coupons,
          energy: energy,
        })
      } catch (e: unknown) {
        if (e instanceof AxiosError && isJwtExpiredError(e)) {
          const telegramInitData = webApp?.initData as string

          await updateTokens(telegramInitData)

          return await fetchUserData(user?.id || '')
        } else {
          throw new Error('Error fetching user data')
        }
      }
    },
    enabled: isLoggedIn && !!tgId && !!webApp?.initData,
    staleTime: 1000,
    retry: 10,
    ...options,
  })
}

export const useQuery_FetchUserTasks_User = (options?: UseQueryOptions<UserTasksResponse>) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const route = useAppSelector(selectRoute)

  const { webApp } = useTelegram()

  return useQuery<UserTasksResponse>({
    queryKey: [CACHE_KEYS.userTasks, route],
    queryFn: async () => {
      try {
        return await fetchUserTasks()
      } catch (e: unknown) {
        if (e instanceof AxiosError && isJwtExpiredError(e)) {
          const telegramInitData = webApp?.initData as string
          await updateTokens(telegramInitData)

          return await fetchUserTasks()
        } else {
          throw new Error('Error fetching user data')
        }
      }
    },
    enabled: isLoggedIn && !!webApp?.initData,
    retry: 10,
    ...options,
  })
}
