import { tonCenterApiAxiosInstance } from '@/services/api/api'
import { TonFullAccountState } from '@/types/tonCenter'

export const getTonExtendedAddressInformation = async (
  tonAddress: string
): Promise<TonFullAccountState> => {
  const response = await tonCenterApiAxiosInstance.get(
    `/getExtendedAddressInformation?address=${tonAddress}`
  )
  return response.data?.result
}
