import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'
import { ReadyState } from 'react-use-websocket'
import { WebSocketMessage } from 'react-use-websocket/src/lib/types'

export interface WsState {
  readyState: ReadyState
  message: WebSocketMessage
}

const initialState: WsState = {
  readyState: ReadyState.CONNECTING,
  message: '',
}

export const wsOptionsSlice = createSlice({
  name: 'wsOptions',
  initialState,
  reducers: {
    setWsOptionsReadyState: (state, action: PayloadAction<ReadyState>) => {
      state.readyState = action.payload
    },
    setWsOptionsMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
  },
})

export const { setWsOptionsReadyState, setWsOptionsMessage } =
  wsOptionsSlice.actions

const selectWsOptions = (state: RootState) => state.wsOptions

export const selectWsOptionsReadyState = (state: RootState) =>
  selectWsOptions(state).readyState

export const selectWsOptionsMessage = (state: RootState) =>
  selectWsOptions(state).message

export default wsOptionsSlice.reducer
