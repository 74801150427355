'use client'

import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { ReactNode, useEffect } from 'react'
import { selectRoute, setRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { useTelegram } from '@/app/providers/TelegramProvider'

export const TelegramButtonsProvider = ({ children }: { children: ReactNode }) => {
  const route = useAppSelector(selectRoute)
  const { webApp } = useTelegram()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!webApp) return

    switch (route) {
      case RoutePaths.tonRaceStepRaceType:
        webApp.BackButton.hide()
        break
      case RoutePaths.raceStepChooseNitro:
        webApp.BackButton.show()
        webApp.BackButton.onClick(() => {
          dispatch(setRoute(RoutePaths.raceStepSelect))
          webApp.HapticFeedback.impactOccurred('light')
        })
        break
      case RoutePaths.tonRaceStepChooseNitro:
        webApp.BackButton.show()
        webApp.BackButton.onClick(() => {
          dispatch(setRoute(RoutePaths.tonRaceStepChooseEntry))
          webApp.HapticFeedback.impactOccurred('light')
        })
        break
      default:
        webApp.BackButton.show()
        webApp.BackButton.onClick(() => {
          dispatch(setRoute(RoutePaths.tonRaceStepRaceType))
          webApp.HapticFeedback.impactOccurred('light')
        })
    }
  }, [route])

  return children
}
